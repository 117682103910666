import React, { useEffect, useRef, useState } from "react";
import { useForm, SubmitHandler, set } from "react-hook-form";
import { type RootState, updateFormSettings, setCheck } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { Success, Warning } from "../../config/swal";
import useAxios from "../../hooks/useAxios";

interface GeneralSetting {
    Name: string;
    PageTitle: string;
    UniqueSubmissions: any;
    FormID: number;
    Captcha: any
}

const GeneralSettings = ({ creator }: any) => {
    const formData = useSelector((state: RootState) => state.project.formData);
    const { _id, hasSecurityKeys, Name, PageTitle, FormID, UniqueSubmissions, Status, Captcha } = formData;
    const Published = Status === 'Published';

    const { register, handleSubmit, formState: { errors }, getValues, setValue, trigger, clearErrors } = useForm<GeneralSetting>({
        defaultValues: { Name, PageTitle, UniqueSubmissions, Captcha :  Captcha ? Captcha : 2 }
    });
    const dispatch = useDispatch();
    const axios = useAxios();
    const submitBtn: any = useRef(null);

    const rules: any = {
        Name: register('Name', {
            required: 'Form name is required',
            onBlur: () => {
                onFieldBlur('Name');
            },
            onChange: (e) => {
                setValue("PageTitle", e.target.value);
                dispatch(setCheck(false));
            }
        }),
        PageTitle: register('PageTitle', {
            onBlur: () => onFieldBlur('PageTitle'),
            onChange: () => {
                dispatch(setCheck(false));
            }
        }),
        UniqueSubmissions: register('UniqueSubmissions', {
            onChange: () => {
                onFieldBlur('UniqueSubmissions');
                dispatch(setCheck(false));
            }
        }),
        Captcha: register('Captcha', {
            onChange: (e) => {
                const value = e.target.value;
                if (value !== '2' || hasSecurityKeys) {
                    clearErrors('Captcha');
                } else {
                    onFieldBlur('Captcha');
                }
                dispatch(setCheck(false));
                const isValid = !errors.Captcha;
                if (isValid) {
                    submitBtn.current.click();
                }
            },
            validate: (value: any) => {
                if (value == '2' && !hasSecurityKeys) return 'Google captcha will not work because Google security keys are not enabled. Go to Settings > Security to set up or contact us at support@optimiser.com to help set it up'
                return true;
            }
        })
    }

    const onSubmit = async (data: any) => {
        try {
            data.UniqueSubmissions = parseInt(data.UniqueSubmissions);
            data.FormID = FormID;
            data._id = _id;
            const message = await updateFormSettings({ patchData: data, axios })(dispatch);
            if (message !== 'success') Warning({ title: message });
            // else Success({ title: 'Form updated' });
            else dispatch(setCheck(true))
            // creator.survey.locTitle.setLocaleText("default", data.Name);
            // creator.doSave(); 
        } catch (error) {
            console.log(error);
        }
    }

    const onFieldBlur = (field: any) => {
        if(field === "Name") submitBtn.current.click();        
        else if (getValues(field) !== formData[field]) submitBtn.current.click();
    }

    useEffect(()=>{
        trigger("Captcha");
    }, [trigger])

    return (
        <>
            <div className="settings-header">
                <div className="header-title">General Settings</div>
            </div>
            <form className="settings-form lg" onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex justify-content-start">
                    <div className="form-group fw-50 mr-2">
                        <label>Form Name <span className={Published ? "chip published" : "chip unpublished"}>{Published ? 'Published' : 'Unpublished'}</span></label>
                        <input placeholder="Enter form name" type="text" {...rules.Name} id="formName" className="form-control" />
                        {errors.Name && <span className="error">{errors.Name.message}</span>}
                    </div>

                </div>
                <div className="form-group fw-50">
                    <label>Page Title</label>
                    <div className="small mb-2">Enter a title to be shown as the browser form title.</div>
                    <input placeholder="Enter page title" type="text" {...rules.PageTitle} id="pageTitle" className="form-control" />
                </div>
                <div className="form-group fw-50">
                    <label>Unique Submission</label>
                    <div className="small mb-2">Use cookies or IP address to prevent multiple submissions</div>
                    <select {...rules.UniqueSubmissions} id="uniqueSubmission" className="form-control">
                        <option value={0}>No Check</option>
                        <option value={1}>Cookies Only</option>
                        <option value={2}>Cookies &amp; IP Check</option>
                    </select>
                </div>

                <div className="form-group fw-50">
                    <label>Captcha</label>
                    <div className="small mb-2">Use captcha to avoid spam form submissions by bots</div>
                    <select {...rules.Captcha} id="captcha" className="form-control">
                        <option value={0}>Not Required</option>
                        <option value={1}>System Captcha</option>
                        <option value={2}>Google Captcha</option>
                    </select>
                    {errors.Captcha && <span className="error">{'' + errors.Captcha.message}</span>}
                </div>

                <div style={{ display: 'none' }}>
                    <button ref={submitBtn} type="submit" className="btn">Save</button>
                </div>
            </form>
        </>
    )
}

export default GeneralSettings;